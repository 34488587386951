@import '../../../../scss/theme-bootstrap';

.sitewide-ie-upgrade {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  background-color: $color-overlay--dark;
  html.ie8 &,
  html.ie7 &,
  html.ie6 & {
    background-color: $color-black;
  }
  &__content {
    @include vertical-horizontal-center;
    z-index: 9999;
    background-color: $color-white;
    color: $color-black;
    width: 60%;
    max-width: 720px;
    html.ie8 &,
    html.ie7 &,
    html.ie6 & {
      left: 20%;
      top: 20%;
    }
    &-copy {
      padding: 25px 30px;
      font-size: 14px;
      line-height: 19px;
      @include breakpoint($landscape-up) {
        font-size: 16px;
      }
    }
    h3 {
      font-family: $font--subheading-alt;
      text-transform: uppercase;
      color: $color-black;
      font-size: 26px;
      width: 85%;
      line-height: 26px;
      @include breakpoint($landscape-up) {
        font-size: 28px;
      }
    }
    p {
      line-height: inherit;
    }
  }
  &__close {
    font-family: $font--sans;
    color: $color-black;
    position: absolute;
    top: 8px;
    right: 10px;
    display: block;
    font-size: 22px;
    cursor: pointer;
  }
  a.sitewide-ie-upgrade__button {
    &:hover {
      color: $color-black;
    }
  }
}
